import { Container } from "react-bootstrap"
import { Element } from "react-scroll"

import Hardware from "../assets/images/section03/Hardware.png"
import ECR from "../assets/images/section03/ECR.png"
import Payment from "../assets/images/section03/Payment.png"
import Software from "../assets/images/section03/Software.png"

const Section03 = () => {
  return (
    <>
      <Element name="section_3">
        <div className="section_3_background">
          <Container className="section_3_services">
            <div className="section_3_features_header">
              <h2>Features</h2>
              <p>
                Our self service kiosk system was designed to grow your business and increase your revenue. <br />
                It is an all-in-one solution, integrated, and most importantly good looking.
              </p>
            </div>

            <section className="section_3_list_cards">
              <div className="section_3_card_container">
                <div className="section_3_icon_container">
                  <img src={Hardware} alt="Hardware Icon" />
                </div>
                <h3>Hardware</h3>
                <p>
                  High Spec hardware engine, with built in 58/80mm Seiko Thermal Printer, NFC read/write, QR & barcode reader, and a front facing
                  camera.
                </p>
              </div>

              <div className="section_3_card_container">
                <div className="section_3_icon_container">
                  <img src={ECR} alt="ECR Icon" />
                </div>
                <h3>ECR Solution</h3>
                <p>Complete ECR solution software can be utilized for many kinds of business types.</p>
              </div>

              <div className="section_3_card_container">
                <div className="section_3_icon_container">
                  <img src={Payment} alt="Payment Icon" />
                </div>
                <h3>Payment</h3>
                <p>Accept multiple cashless payment platforms on one single system, both digital wallets and credit/debit cards.</p>
              </div>

              <div className="section_3_card_container">
                <div className="section_3_icon_container">
                  <img src={Software} alt="Software Icon" />
                </div>
                <h3>Software Add-ons</h3>
                <p>Endless software add-ons that will support your business expansion.</p>
              </div>
            </section>
          </Container>
        </div>
      </Element>
    </>
  )
}

export default Section03
