import { Container } from "react-bootstrap"
// import { Element } from "react-scroll"

import logo_qios_white from "../assets/logo/Qios_logo-white-1.png"
import Instagram from "../assets/images/social_links/Instagram_white.png"
import Linkedin from "../assets/images/social_links/Linkedin_white.png"
// import Facebook from "../assets/images/social_links/Facebook_white.png"
// import Twitter from "../assets/images/social_links/Twitter_white.png"
// import Whatsapp from "../assets/images/social_links/Whatsapp_white.png"

const BottomFooterBar = () => {
  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : ""
    if (subject) params += `subject=${encodeURIComponent(subject)}`
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`

    return <a href={`mailto:${email}${params}`}>{children}</a>
  }

  const email_body = `Full Name : 
Phone Number : 
Email : 
Message : 

This message is auto generated and sent via Mail To from Qios Website.
`
  const email_destination = `support@qios-id.com`

  return (
    <>
      <div className="section_footer_contact">
        <Container className="section_footer_listing">
          <div className="section_footer_logo">
            <img src={logo_qios_white} alt="Logo Qios Bottom Footer Contact Bar" />
          </div>

          <div className="section_footer_contact_list">
            <div className="section_footer_contact_item">
              <h5>Office</h5>
              <h6>The Vida Office Building 6th Floor</h6>
              <h6>Jl. Raya Perjuangan No. 8</h6>
              <h6>Jakarta 11530, Indonesia</h6>
            </div>

            <div className="section_footer_contact_item">
              <h5>Contact</h5>
              <Mailto email={email_destination} subject="" body={email_body}>
                <h6>support@qios-id.com</h6>
              </Mailto>
              <a href="https://api.whatsapp.com/send?phone=+628119930603">
                <h6>+62 811 9930 603</h6>
              </a>
            </div>

            <div className="section_footer_contact_item">
              <h5>Social</h5>

              <div className="section_footer_social_link_list">
                <a href="http://instagram.com/_u/qiosofficial/">
                  <div className="section_footer_social_link_icon">
                    <img src={Instagram} alt="Instagram Logo" />
                  </div>
                </a>

                <a href="https://www.linkedin.com/company/qios-id/">
                  <div className="section_footer_social_link_icon">
                    <img src={Linkedin} alt="Linkedin Logo" />
                  </div>
                </a>

                {/* <a href="http://www.facebook.com">
                  <div className="section_footer_social_link_icon">
                    <img src={Facebook} alt="Facebook Logo" />
                  </div>
                </a> */}

                {/* <a href="http://www.twitter.com">
                  <div className="section_footer_social_link_icon">
                    <img src={Twitter} alt="Twitter Logo" />
                  </div>
                </a> */}
              </div>
            </div>
          </div>
        </Container>

        <Container className="section_footer_copyright">
          <h6>© Qios 2021 | All Rights Reserved</h6>
        </Container>
      </div>
    </>
  )
}

export default BottomFooterBar
