import { Container } from "react-bootstrap"
import { Element } from "react-scroll"

import FormContact from "./FormContact.jsx"

const Section05 = () => {
  return (
    <>
      <Element name="section_5">
        <div className="section_5_background">
          <Container className="section_5_form">
            <div className="section_5_header">
              <h2>Contact</h2>
              <p>
                For business inquiries drop us a message <br /> We will get back to you as soon as possible!
              </p>
            </div>
            <FormContact />
          </Container>
        </div>
      </Element>
    </>
  )
}

export default Section05
