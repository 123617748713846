import { Form, Col, Button } from "react-bootstrap"
import { useState, useEffect, useRef } from "react"

import { validate_empty_form, validate_email, validate_length_min_4, validate_length_max_30, validate_length_max_150 } from "../helpers/validations"
import Toast from "../helpers/toast.js"

import emailjs from "emailjs-com"

const FormContact = () => {
  // set initial state value(s) for example:
  const [form, setForm] = useState({})

  // set error messages to display to the user
  const [errors, setErrors] = useState({})

  // set the help of useRef to test if it's the first render
  const firstRender = useRef(true)

  // counter to guard re-render-ing issues
  let count = 0

  // USE EFFECT for every change in our state this will be fired
  // can add validation here and disable the save button if required
  useEffect(() => {
    // skip validation on first render
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    // counter to guard re-render-ing issues
    count++
    console.log(`Console log to check infinite looping >>>`, count)
  }, [count])

  // Setting field form in the contact support
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      })
  }

  // Form Validations
  const formValidation = () => {
    const { firstName, lastName, email, phoneNumber, subject, message } = form
    const newErrors = {}
    // First Name validations
    if (validate_empty_form(firstName)) {
      newErrors.firstName = "First name can not be blank!"
    } else if (validate_length_max_30(firstName)) {
      newErrors.firstName = "First name can not be more than 30 characters!"
    }
    // Last Name validations
    if (validate_empty_form(lastName)) {
      newErrors.lastName = "Last name can not be blank!"
    } else if (validate_length_max_30(lastName)) {
      newErrors.lastName = "Last name can not be more than 30 characters!"
    }
    // Email validations
    if (validate_empty_form(email)) {
      newErrors.email = "Email can not be blank!"
    } else if (validate_email(email) === false) {
      newErrors.email = "Email is not valid"
    }
    // Phone number validations
    if (validate_empty_form(phoneNumber)) {
      newErrors.phoneNumber = "Phone number can not be blank!"
    } else if (validate_length_min_4(phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be more than 4 digits!"
    } else if (typeof +phoneNumber !== "number") {
      newErrors.phoneNumber = "Phone number must be a number"
    }
    // Subject validations
    if (validate_empty_form(subject)) {
      newErrors.subject = "Subject can not be blank!"
    } else if (validate_length_max_150(subject)) {
      newErrors.subject = "Subject can not be more than 150 characters!"
    }
    // Message validations
    if (validate_empty_form(message)) {
      newErrors.message = "Message can not be blank!"
    }

    return newErrors
  }

  // Reseting input form field
  const resetFormFields = () => {
    document.getElementById("form-contact-qios").reset()
    setForm({})
  }

  // Button submit to send email
  const handleSubmit = (e) => {
    e.preventDefault()

    console.log(`Button submit clicked`)
    // Get our new errors
    const newErrors = formValidation()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors)
    } else {
      // No errors! Put any logic here for the form submission!
      console.log(`Form criteria fullfilled!`)

      const serviceID = "service_c6y5w2f"
      const templateId = "template_h9warck"
      const userId = "user_oVfjtEKWH3UK30pGFy8yA"
      const variablesForm = {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        phoneNumber: form.phoneNumber,
        subject: form.subject,
        message: form.message,
      }
      sendFeedback(serviceID, templateId, variablesForm, userId)
    }
  }

  // Sending message via email JS with contact support email being attached in the
  // dashboard of email christ.harlyandi@qios-id.com
  const sendFeedback = (serviceID, templateId, variables, userId) => {
    emailjs
      .send(serviceID, templateId, variables, userId)
      .then((res) => {
        console.log("Email successfully sent!")
        Toast.fire({
          icon: "success",
          title: "Email has been sent successfully",
        })
        // e.target.reset()
        resetFormFields()
      })
      .catch((err) => {
        console.error("There has been an Error.", err)
        Toast.fire({
          icon: "error",
          title: "Email has not been sent",
        })
      })
  }

  return (
    <>
      <div className="section_5_form_div">
        <Form id="form-contact-qios" action="mailto:lorenzo.harlyandi@gmail.com" method="POST" enctype="text/plain">
          <Form.Row>
            <Form.Group className="section_5_form_group" as={Col} md="6" controlId="validationFirstName">
              <Form.Label className="section_5_form_label">First Name</Form.Label>
              <Form.Control
                name="firstName"
                type="text"
                placeholder="Type Your First Name"
                className="section_5_form_control"
                onChange={(e) => {
                  setField("firstName", e.target.value)
                }}
                isInvalid={!!errors.firstName}
              />
              <Form.Control.Feedback className="section_5_form_control_feedback" type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="section_5_form_group" as={Col} md="6" controlId="validationLastName">
              <Form.Label className="section_5_form_label">Last Name</Form.Label>
              <Form.Control
                name="lastName"
                type="text"
                placeholder="Type Your Last Name"
                className="section_5_form_control"
                onChange={(e) => {
                  setField("lastName", e.target.value)
                }}
                isInvalid={!!errors.lastName}
              />
              <Form.Control.Feedback className="section_5_form_control_feedback" type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group className="section_5_form_group" as={Col} md="6" controlId="validationEmail">
              <Form.Label className="section_5_form_label">Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="Type Your Email"
                className="section_5_form_control"
                onChange={(e) => {
                  setField("email", e.target.value)
                }}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback className="section_5_form_control_feedback" type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="section_5_form_group" as={Col} md="6" controlId="validationPhoneNumber">
              <Form.Label className="section_5_form_label">Phone Number</Form.Label>
              <Form.Control
                name="phoneNumber"
                type="number"
                placeholder="Type Your Phone Number"
                className="section_5_form_control"
                onChange={(e) => {
                  setField("phoneNumber", e.target.value)
                }}
                isInvalid={!!errors.phoneNumber}
              />
              <Form.Control.Feedback className="section_5_form_control_feedback" type="invalid">
                {errors.phoneNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Group className="section_5_form_group" controlId="validationSubject">
            <Form.Label className="section_5_form_label">Subject</Form.Label>
            <Form.Control
              name="subject"
              type="text"
              placeholder="Type Your Subject"
              className="section_5_form_control"
              onChange={(e) => {
                setField("subject", e.target.value)
              }}
              isInvalid={!!errors.subject}
            />
            <Form.Control.Feedback className="section_5_form_control_feedback" type="invalid">
              {errors.subject}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="section_5_form_group" controlId="validationMessage">
            <Form.Label className="section_5_form_label">Message</Form.Label>
            <Form.Control
              name="message"
              type="text"
              as="textarea"
              rows={5}
              placeholder="Type Your Message"
              className="section_5_form_control_text_area"
              onChange={(e) => {
                setField("message", e.target.value)
              }}
              isInvalid={!!errors.message}
            />
            <Form.Control.Feedback className="section_5_form_control_feedback" type="invalid">
              {errors.message}
            </Form.Control.Feedback>
          </Form.Group>

          <div align="right">
            <Button type="submit" className="section_5_button" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

export default FormContact
