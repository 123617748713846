// ES6 Modules or TypeScript
import Swal from "sweetalert2"

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
})

export default Toast
