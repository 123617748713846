import { Container } from "react-bootstrap"
import { Element } from "react-scroll"

import Benefits from "../assets/images/section04/Benefit.png"
import Checklist from "../assets/images/section04/Checklist.png"

const Section04 = () => {
  return (
    <>
      <Element name="section_4">
        <Container className="section_4_benefits">
          <div className="section_4_left_side">
            <img src={Benefits} alt="Benefits Clock and Hourglass" />
          </div>

          <div className="section_4_right_side">
            <div className="section_4_benefits_header">
              <h2>Benefits</h2>
            </div>

            <div className="section_4_list_of_benefits">
              <div className="section_4_list_item">
                <div className="section_4_item_card">
                  <div className="section_4_item_header">
                    <div className="section_4_checklist">
                      <img src={Checklist} alt="Checklist Logo" />
                    </div>
                    <h3>Shorter Waiting Times</h3>
                  </div>

                  <div className="section_4_content">
                    <p>Workers can focus on completing the orders, rather than juggling on cashier job & producing the product.</p>
                  </div>
                </div>
              </div>

              <div className="section_4_list_item">
                <div className="section_4_item_card">
                  <div className="section_4_item_header">
                    <div className="section_4_checklist">
                      <img src={Checklist} alt="Checklist Logo" />
                    </div>
                    <h3>Eliminating Order Errors</h3>
                  </div>

                  <div className="section_4_content">
                    <p>All order notes are printed, no more mishearing custom orders.</p>
                  </div>
                </div>
              </div>

              <div className="section_4_list_item">
                <div className="section_4_item_card">
                  <div className="section_4_item_header">
                    <div className="section_4_checklist">
                      <img src={Checklist} alt="Checklist Logo" />
                    </div>
                    <h3>Cost Cutting</h3>
                  </div>

                  <div className="section_4_content">
                    <p>Reduce operational cost on worker’s wage.</p>
                  </div>
                </div>
              </div>

              <div className="section_4_list_item">
                <div className="section_4_item_card">
                  <div className="section_4_item_header">
                    <div className="section_4_checklist">
                      <img src={Checklist} alt="Checklist Logo" />
                    </div>
                    <h3>Covid-19 Free</h3>
                  </div>

                  <div className="section_4_content">
                    <p>Easier social distancing management, Cashless, No Direct contact with anyone</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Element>
    </>
  )
}

export default Section04
