import "bootstrap/dist/css/bootstrap.min.css"
import "./css/main.css"
import React from "react"

import { TopNavigationBar, Section01, Section02, Section03, Section04, Section05, BottomFooterBar } from "./components"

function App() {
  return (
    <React.Fragment>
      <TopNavigationBar />

      <Section01 title="Section 1" id="section1" />
      <Section02 title="Section 2" id="section2" />
      <Section03 title="Section 3" id="section3" />
      <Section04 title="Section 4" id="section4" />
      <Section05 title="Section 5" id="section5" />

      <BottomFooterBar />
    </React.Fragment>
  )
}

export default App
