import { useState, useEffect } from "react"
import { Nav, Navbar, Container } from "react-bootstrap"

import { Link } from "react-scroll"
import { Squash as Hamburger } from "hamburger-react"

import qios_logo_01 from "../assets/logo/Qios_logo-01-crop.png"

const TopNavigationBar = () => {
  const [expanded, setExpanded] = useState(false)

  const [show, setShow] = useState(false)
  const navbarControl = () => {
    if (window.scrollY > 100) {
      setShow(false)
    } else {
      setShow(true)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", navbarControl)
    return () => window.removeEventListener("scroll", navbarControl)
  }, [])

  return (
    <>
      <div className={show ? "top-navigation" : "top-navigation transparent-navigation"}>
        <Navbar expanded={expanded} collapseOnSelect expand="lg">
          <Container>
            <Navbar.Brand className="top-navigation-logo" href="#home">
              <img src={qios_logo_01} alt="Logo Qios Top Navigation Bar" />
            </Navbar.Brand>

            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav">
              <Hamburger toggled={expanded} toggle={setExpanded} color="#5fb596" size={20} />
            </Navbar.Toggle>

            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto"></Nav>

              <Nav className="top-navigation-list">
                <div className="top-navigation-list-items">
                  <Link
                    onClick={() => setExpanded(false)}
                    activeClass="active"
                    to="section_1"
                    spy={true}
                    smooth={true}
                    duration={250}
                    style={{ display: "inline-block", margin: "20px" }}
                  >
                    Home
                  </Link>
                </div>

                <div className="top-navigation-list-items">
                  <Link
                    onClick={() => setExpanded(false)}
                    activeClass="active"
                    to="section_2"
                    spy={true}
                    smooth={true}
                    duration={250}
                    style={{ display: "inline-block", margin: "20px" }}
                  >
                    About
                  </Link>
                </div>

                <div className="top-navigation-list-items">
                  <Link
                    onClick={() => setExpanded(false)}
                    activeClass="active"
                    to="section_3"
                    spy={true}
                    smooth={true}
                    duration={250}
                    style={{ display: "inline-block", margin: "20px" }}
                  >
                    Services
                  </Link>
                </div>

                <div className="top-navigation-list-items">
                  <Link
                    onClick={() => setExpanded(false)}
                    activeClass="active"
                    to="section_4"
                    spy={true}
                    smooth={true}
                    duration={250}
                    style={{ display: "inline-block", margin: "20px" }}
                  >
                    Benefits
                  </Link>
                </div>

                <div className="top-navigation-list-items">
                  <Link
                    onClick={() => setExpanded(false)}
                    activeClass="active"
                    to="section_5"
                    spy={true}
                    smooth={true}
                    duration={250}
                    style={{ display: "inline-block", margin: "20px" }}
                  >
                    Contact
                  </Link>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  )
}

export default TopNavigationBar
