import { Container, Button } from "react-bootstrap"
import Machine_1 from "../assets/images/section01/Machine_1.png"

import Scroll from "react-scroll"
const Element = Scroll.Element
const Link = Scroll.Link

const Section01 = () => {
  const scrollToBottom = (e) => {
    e.preventDefault()
    // console.log(`Button Contact clicked`)
    Scroll.animateScroll.scrollToBottom({ duration: 500 })
  }

  return (
    <>
      <Element name="section_1">
        <div className="section_1_background">
          <Container className="section_1_home">
            <div className="section_1_left_side">
              <h1>
                Life will be <span>easier</span> with <span>Qios</span>
              </h1>
              <p>Your complete kiosk solution for better days</p>
              <Button className="section_1_button" onClick={scrollToBottom}>
                <Link activeClass="active" to="section_5" spy={true} smooth={true} duration={250}>
                  Contact Us
                </Link>
              </Button>
            </div>
            <div className="section_1_right_side">
              <img src={Machine_1} alt="Machine 1" />
            </div>
          </Container>
        </div>
      </Element>
    </>
  )
}

export default Section01
