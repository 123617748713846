import { Container } from "react-bootstrap"
import { Element } from "react-scroll"

import Who_We_Are_1 from "../assets/images/section02/Who_We_Are_1.png"
import Who_We_Are_2 from "../assets/images/section02/Who_We_Are_2.png"

const Section02 = () => {
  return (
    <>
      <Element name="section_2">
        <Container className="section_2_about">
          <div className="section_2_part_1">
            <div className="section_2_part_1_left_side">
              <h6>Who we are</h6>
              <h2>
                Efficient & Intuitive <br />
                Experienced Self-Service Kiosk
              </h2>
              <div className="section_2_part_1_content">
                <p>
                  Our solution then is a simple, fun & easy to use, and most importantly extremely efficient Self-Service Kiosk. It is a device that
                  could allow more efficient operational working flow, utilising human resources for a far better purpose. It is also fun and
                  intuitive to use, with exciting pictures and explanations of the products sold, which might attract customers further to make bigger
                  quantity orders.
                </p>
              </div>
            </div>

            <div className="section_2_part_1_right_side">
              <img src={Who_We_Are_1} alt="Self-Service Kiosk Chart" />
            </div>
          </div>

          <div className="section_2_part_2">
            <div className="section_2_part_2_left_side">
              <img src={Who_We_Are_2} alt="Grow Your Business Chart" />
            </div>

            <div className="section_2_part_2_right_side">
              <h6>Who we are</h6>
              <h2>Grow Your Business</h2>
              <div className="section_2_part_2_content">
                <p>
                  We will always focus on helping businesses grow and engage the customers when interacting with our kiosks. Our journey doesn’t stop
                  here. We dream a simple & efficient world and this is the start of our dream.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Element>
    </>
  )
}

export default Section02
